import React from 'react'

class LinkedinIcon extends React.Component {
  render() {
    return (
      <svg
        style={this.props.svgStyles}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.03 26.26"
        aria-hidden="true"
      >
        <circle cx="13.95" cy="13" r="12.35"/>
        <g id="layer1">
          <g id="g3019">
            <path id="path28" fill="#fff" d="M10.43,19.16V10.85H7.67v8.31ZM9.05,9.71a1.44,1.44,0,1,0,0-2.87A1.44,1.44,0,1,0,9,9.71Z"/>
            <path id="path30" fill="#fff" d="M12,19.16h2.77V14.52a1.78,1.78,0,0,1,.09-.68,1.51,1.51,0,0,1,1.41-1c1,0,1.4.77,1.4,1.88v4.45H20.4V14.39c0-2.55-1.37-3.74-3.18-3.74a2.75,2.75,0,0,0-2.51,1.4h0v-1.2H12c0,.78,0,8.31,0,8.31Z"/>
          </g>
        </g>
     </svg>
    );
  }
}
LinkedinIcon.defaultProps = {
  color: "#2fa843",
  height: "35px",
  svgStyles: {},
  width: "35px",
};
export default LinkedinIcon