import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby";


const Header = ({ siteTitle, path, language }) => {
  const links = useStaticQuery(graphql`
    query {
      allStrapiNavLink(sort: {order: DESC, fields: strapiId}) {
        edges {
          node {
            id
            label
            link
            language
          }
        }
      }
    }
  `).allStrapiNavLink.edges
  const expand = (e)=>{
    var elem = document.querySelector(".lbe-nav-mobile > div")
    if(elem.classList.contains("expand")){
      elem.classList.remove("expand")
    } else {
      elem.classList.add("expand")
    }
  }
  const handleLanguageSelector = (e)=>{
    if(e.target.value !== language){
      if(e.target.value === "english"){
        if(path === "/"){
          window.location.pathname = '/home-en/'
        } else {
          window.location.pathname = `${path.slice(0,path.length-1)}-en/`
        }
      } else if(e.target.value === "french"){
        if(path === "/home-en/"){
          window.location.pathname = '/'
        } else if(path === "/tips-tools-en/") {
          window.location.pathname = '/tips-tools/'
        } else {
          window.location.pathname = path.split("-")[0]
        }
      }
    }
  }
  return (
  <header style={{ background: `white` }} >
    <div className="lbe-nav" style={{ margin: `0 auto`, maxWidth: 1140, padding: `30px 2rem`, textAlign:"right" }} >
      {links.filter((l)=>l.node.language === language).map((l)=>{
        return(
          <Link
            key={l.node.id}
            to={l.node.link}
            className="nav-link"
            style={{
              color: (l.node.link.length===1?l.node.link:l.node.link+"/") === path ? '#2fa843' : `#1e3b4b`,
              textDecoration: `none`
            }}
          >
            {l.node.label}
          </Link>
        )
      })}
      <div style={{display:"inline-block"}} className="nav-link">
        <span style={{marginRight:"8px"}}>Language:</span>
        <select value={language} onChange={handleLanguageSelector}>
          <option  value="french">FR</option>
          <option  value="english">EN</option>
        </select>
      </div>
    </div>
    <div className="lbe-nav-mobile">
      <div>
        <div className="nav-hamburger" role="button" tabIndex="0" onClick={expand}>
          <div aria-hidden="true" className="bar1"></div>
          <div aria-hidden="true" className="bar2"></div>
          <div aria-hidden="true" className="bar3"></div>
        </div>
        {links.filter((l)=>l.node.language === language).map((l)=>{
          return(
            <Link 
              key={`${l.node.id}-mobile`}
              to={l.node.link}
              className="nav-link"
              style={{
                color: (l.node.link.length===1?l.node.link:l.node.link+"/") === path ? '#2fa843' : `#1e3b4b`,
                textDecoration: `none`
              }}
            >
              {l.node.label}
            </Link>
          )
        })}
        <div style={{display:"inline-block"}} className="nav-link">
          <span style={{marginRight:"8px"}}>Language:</span>
          <select value={language} onChange={handleLanguageSelector}>
            <option  value="french">FR</option>
            <option  value="english">EN</option>
          </select>
        </div>
      </div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
