/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, createContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import LinkedinIcon from '../components/icons/linkedin_icon'
import YoutubeIcon from '../components/icons/youtube_icon'
import Header from "./header"
import "./global/global.css"
import "./layout.css"

export const LangaugeStateContext = createContext(null)

const Layout = ({ children, path, language }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header language={language} path={path} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="layout" >
        <LangaugeStateContext.Provider value={language}>
          <main>{children}</main>
        </LangaugeStateContext.Provider>
        <footer style={{ marginTop: `2rem`, }} >
          <div>
            <a target="_blank" href="https://www.facebook.com/Lead-By-Example-Foundation-119561488102531">
              <img alt="" src="https://lbe-assets.sfo2.digitaloceanspaces.com/Icon_Facebook.svg" />
            </a>
            <a target="_blank" href="https://twitter.com/LBE_Mentoring">
              <img alt="" src="https://lbe-assets.sfo2.digitaloceanspaces.com/Icon_Twitter.svg" />
            </a>
            <a target="_blank" href="https://www.instagram.com/lbe.mentoring">
              <img alt="" src="https://lbe-assets.sfo2.digitaloceanspaces.com/Icon_Instagram.svg" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/lbe-mentoring/">
              <LinkedinIcon />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCR-hOFLySGi7hqfxjOVUiAw">
              <YoutubeIcon />
            </a>
          </div>
          <p>© {new Date().getFullYear()} Lead By Example</p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
