import React from 'react'

class YoutubeIcon extends React.Component {
  render() {
    return (
      <svg
        style={this.props.svgStyles}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.03 26.26"
        aria-hidden="true"
      >
        <circle cx="13.36" cy="13.38" r="12.35"/>
        <path fill="#fff" d="M21.4,9.34a2.12,2.12,0,0,0-1.49-1.49,48.92,48.92,0,0,0-6.56-.36,49.06,49.06,0,0,0-6.55.37A2.08,2.08,0,0,0,5.31,9.35a22.79,22.79,0,0,0,0,8.07,2.12,2.12,0,0,0,1.49,1.49,48.71,48.71,0,0,0,6.55.36,48.92,48.92,0,0,0,6.56-.36,2.13,2.13,0,0,0,1.49-1.49A22.84,22.84,0,0,0,21.4,9.34Z"/>
        <path d="M11.68,15.9,16,13.38l-4.36-2.52Z"/>
     </svg>
    );
  }
}
YoutubeIcon.defaultProps = {
  color: "#2fa843",
  height: "35px",
  svgStyles: {},
  width: "35px",
};
export default YoutubeIcon